import { calculateLoanInstallments, calculateLoanTotal } from 'src/shared/helpers'
import { INSSTypeProps, reducerType, actionProps } from 'src/components/PayrollSimulator/types'

const MIN_VALUE = 1600

// rates Fator PMT
export const PAYROLL_TYPE: INSSTypeProps = {
  inss: {
    parcels: [ 24, 36, 48, 60, 72, 84 ],
    rates: {
      24: 0.0520784597986341,
      36: 0.0378936040344793,
      48: 0.0308965182183742,
      60: 0.0267843303492508,
      72: 0.0241156129958146,
      84: 0.0222706190588951,
    },
    minValue: 1600,
    maxValue: 400000,
  },
}

function calculatePercentage (inputValue: number, maxValue: number) {
  const range = Math.round(maxValue - MIN_VALUE)
  return Math.round((inputValue - MIN_VALUE) * 100 / range)
}

function calculateWithFees (simulationType: string, qtdParcel: number, inputValue: number) {
  const taxa = 0.016
  const carencia = 0

  if (simulationType === 'total') {
    return calculateLoanTotal(taxa, inputValue, qtdParcel, carencia)
  }
  return calculateLoanInstallments(taxa, inputValue, qtdParcel, carencia)
}

function adjustMaxValue (simulationType: string, qtdParcel: number, page: keyof INSSTypeProps) {
  if (simulationType === 'parcel') {
    return Math.round((PAYROLL_TYPE[page].maxValue * PAYROLL_TYPE[page].rates[qtdParcel]) * 100) / 100
  } else {
    return PAYROLL_TYPE[page].maxValue
  }
}

function adjustInputValue (simulationType: string, currentInputValue: number, maxValue: number) {
  if (simulationType === 'total') {
    return currentInputValue
  }
  return maxValue < currentInputValue ? maxValue : currentInputValue
}

export function initialState (page: keyof INSSTypeProps = 'inss') {
  return {
    simulationType: 'total',
    inputValue: PAYROLL_TYPE[page].minValue,
    resultValue: (PAYROLL_TYPE[page].minValue * PAYROLL_TYPE[page].rates[PAYROLL_TYPE[page].parcels[PAYROLL_TYPE[page].parcels.length - 1]]),
    qtdParcel: PAYROLL_TYPE[page].parcels[PAYROLL_TYPE[page].parcels.length - 1],
    percentage: PAYROLL_TYPE[page].rates[PAYROLL_TYPE[page].parcels[PAYROLL_TYPE[page].parcels.length - 1]],
    maxValue: PAYROLL_TYPE[page].maxValue,
    page: page,
  }
}

export const actions = {
  setSimulationType: (simulationType: string) => ({
    type: 'SET_SIMULATION_TYPE',
    simulationType,
  }),
  setInputValue: (inputValue: number) => ({
    type: 'SET_INPUT_VALUE',
    inputValue,
  }),
  setQtdParcel: (qtdParcel: number) => ({
    type: 'SET_PARCEL',
    qtdParcel,
  }),
}

const payrollSimulatorReducer = (state: reducerType, action: actionProps) => {
  switch (action.type) {
    case 'SET_SIMULATION_TYPE':
      return {
        ...state,
        simulationType: action.simulationType,
        inputValue: state.resultValue,
        resultValue: state.inputValue,
        maxValue: adjustMaxValue(action.simulationType, state.qtdParcel, state.page),
      }
    case 'SET_PARCEL': {
      const maxValue = adjustMaxValue(state.simulationType, action.qtdParcel, state.page)
      const inputValue = adjustInputValue(state.simulationType, state.inputValue, maxValue)
      return {
        ...state,
        qtdParcel: action.qtdParcel,
        maxValue,
        inputValue,
        percentage: calculatePercentage(inputValue, maxValue),
        resultValue: calculateWithFees(state.simulationType, action.qtdParcel, inputValue),
      } }
    case 'SET_INPUT_VALUE': {
      const _inputValue = Math.min(action.inputValue, state.maxValue)
      return {
        ...state,
        inputValue: _inputValue,
        percentage: calculatePercentage(_inputValue, state.maxValue),
        resultValue: calculateWithFees(state.simulationType, state.qtdParcel, _inputValue),
      }
    }
  }
}
export default payrollSimulatorReducer
