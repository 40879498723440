import React from 'react'

// Components
import DataLayerEvent from 'src/components/DataLayerEvent'
import Img from 'gatsby-image'

// PageContext
import usePageQuery from '../../pageQuery'

// Styles
import * as S from './style'
import ScrollTo from 'src/components/ScrollTo'

const ComoContratarOEmprestimo = () => {
  const imagesData = usePageQuery()

  return (
    <S.Section className='py-5 bg-white' id='como-contratar-emprestimo-consignado'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <Img fluid={imagesData.bannerComoContratarEmprestimoConsignado.fluid} alt='Ilustração aplicativo Super App Inter' />
          </div>
          <div className='col-12 col-md-6 offset-lg-1'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500'>Como contratar o Empréstimo Consignado INSS?</h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Contrate o seu empréstimo para aposentados e pensionistas INSS sem precisar enfrentar filas, de forma 100% digital ou, se preferir, falando com um de nossos especialistas. Veja como é simples e rápido:
            </p>
            <div className='mt-4'>
              <div className='d-flex mb-4'>
                <div className='mr-2'>
                  <S.Number className='d-flex justify-content-center align-items-center'>1</S.Number>
                </div>
                <div>
                  <h3 className='fs-16 lh-20 fw-700 text-grayscale--500 mb-2'>Faça uma simulação</h3>
                  <p className='fs-16 lh-20 fw-400 text-grayscale--500 mb-0'>Simule o seu Consignado INSS aqui no site ou pelo app Inter</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='mr-2'>
                  <S.Number className='d-flex justify-content-center align-items-center'>2</S.Number>
                </div>
                <div>
                  <h3 className='fs-16 lh-20 fw-700 text-grayscale--500 mb-2'>Selecione a forma de contratação</h3>
                  <p className='fs-16 lh-20 fw-400 text-grayscale--500 mb-0'>Escolha se deseja contratar de forma 100% digital</p>
                </div>
              </div>
            </div>
            <ScrollTo
              to='#hero-section'
              section='dobra_06'
              sectionName='Como contratar o Empréstimo Consignado INSS?'
              elementName='Quero contratar agora!'
            >
              <DataLayerEvent elementAction='click button' sectionOrder='6'>
                <a
                  className='btn btn-orange--extra fs-12 lh-17 fw-700 rounded-2 text-none mt-4 mw-100'
                >
                  Quero contratar agora!
                </a>
              </DataLayerEvent>
            </ScrollTo>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ComoContratarOEmprestimo
