import React from 'react'

// Components
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import DataLayerEvent from 'src/components/DataLayerEvent'
// Styles
import { Section, CardWrapper, Card, CardTitle, CardDescription } from './style'

// Json
import dataJson from './data.json'
import ScrollTo from 'src/components/ScrollTo'

interface IIndicateJson {
  icon: string;
  title: string;
  description: string;
}

const GanhePorAmigoIndicado = () => {
  const indicateJson: IIndicateJson[] = dataJson.indicate

  return (
    <Section className='py-5 bg-grayscale--100' id='vantagens'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-md-center mx-auto'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-2 mb-md-3'>Quais as vantagens do Consignado <span className='d-lg-block d-xl-inline'>INSS Inter?</span></h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500 mb-4'>
              No Inter, você tem <span className='fw-700'>um atendimento exclusivo com especialistas</span> que personalizam o seu empréstimo de acordo com as suas necessidades e muitas outras vantagens que você só encontra aqui.
            </p>
          </div>
        </div>

        <div className='row d-flex'>
          {indicateJson.map((data: IIndicateJson) => (
            <CardWrapper key={data.icon} className='col-12 col-md-6 col-lg-4'>
              <Card>
                <CardTitle>
                  <div>
                    <OrangeIcon size='MD' color='#161616' icon={data.icon} />
                  </div>
                </CardTitle>
                <span className='fs-18 lh-22 fw-700 text-grayscale--500 mb-2'>{data.title}</span>
                <CardDescription>{data.description}</CardDescription>
              </Card>
            </CardWrapper>
          ))}
        </div>

        <div className='text-center'>
          <ScrollTo
            to='#hero-section'
            section='dobra_05'
            sectionName='Quais as vantagens do Consignado INSS Inter?'
            elementName='Aproveitar as vantagens!'
          >
            <DataLayerEvent elementAction='click button' sectionOrder='5'>
              <a className='fs-14 fw-600 btn btn--lg btn-orange--extra text-none rounded-3 mt-3 mt-lg-4 mb-md-0'>
                Aproveitar as vantagens!
              </a>
            </DataLayerEvent>
          </ScrollTo>
        </div>
      </div>
    </Section>
  )
}

export default GanhePorAmigoIndicado
