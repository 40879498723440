import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  display: flex;
  justify-content: center;
  max-width: 100%;

  .dots {
    position: static;
    margin-top: 10px;

    li button {
      border: 2px solid ${orange.extra};
    }

    .react-multi-carousel-dot--active button {
      background: ${orange.extra};
    }
  }

  .container {
    @media (min-width: 769px) {
      max-width: 100%;
      margin: none;
      padding: 0;
    }
  }

  .react-multi-carousel-track {
    @media (min-width: ${breakpoints.lg}) {
      padding-left: 125px;
    }
  }

  .gatsby-image-wrapper {
    position: initial !important;
  }
`

export const Figure = styled.figure`
  position: relative;
  height: 360px;
  border-radius: 23px;
  overflow: hidden;

  @media (min-width: ${breakpoints.md}) {
    height: 222px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 247px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 360px;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 37px 22px;
    background: rgba(22, 22, 22, 0.35);
    display: flex;
    align-items: flex-end;
    z-index: 0;

    @media (min-width: ${breakpoints.md}) {
      padding: 36px 60px;
    }

    .text {
      z-index: 1;
    }
  }
`

export const Circle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  outline: none;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.left {
    left: 22px;
    display: none;

    @media (min-width: ${breakpoints.md}) {
      display: block;
    }
  }

  &.right {
    right: 22px;
    display: none;

    @media (min-width: ${breakpoints.md}) {
      display: block;
    }
  }
`
