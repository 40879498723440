import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import Background from '../../assets/images/background-portabilidade-de-emprestimo-consignado.png'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${orange.extra};

  padding-top: 40px;
  padding-bottom: 40px;
  @media ${device.tablet} {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  @media ${device.desktopLG} {
    background-image: url(${Background});
    background-size: 635px;
    background-repeat: no-repeat;
    background-position: 523px center;
  }

  @media ${device.desktopXL} {
    background-size: 725px;
    background-position: 822px center;
  }

`
