import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import pageContext from './pageContext.json'

import Hero from './sections/Hero/_Hero'
import WhatAreYouLookingFor from './sections/WhatAreYouLookingFor/_index'
import CarouselNovidades from './sections/CarouselNovidades/_CarouselNovidades'
import QuemPodeContratarOEmprestimo from './sections/QuemPodeContratarOEmprestimo/_index'
import ComoContratarOEmprestimo from './sections/ComoContratarOEmprestimo/_index'
import VantagensEmprestimo from './sections/VantagensEmprestimo/_VantagensEmprestimo'
import MensageAlerta from './sections/MensageAlerta/_MensageAlerta'
import TenhaAindaMaisBeneficios from './sections/TenhaAindaMaisBeneficios/_index'
import PortabilidadeDeEmprestimoConsignado from './sections/PortabilidadeDeEmprestimoConsignado/_PortabilidadeDeEmprestimoConsignado'

import { Wrapper } from './style'
import IndiqueEGanhe from './sections/IndiqueEGanhe/_index'

const EmprestimoConsignadoPublico = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <WhatAreYouLookingFor sendDatalayerEvent={sendDatalayerEvent} />
        <CarouselNovidades />
        <QuemPodeContratarOEmprestimo />
        <VantagensEmprestimo />
        <ComoContratarOEmprestimo />
        <PortabilidadeDeEmprestimoConsignado />
        <IndiqueEGanhe />
        <TenhaAindaMaisBeneficios />
        <MensageAlerta />
      </Layout>
    </Wrapper>
  )
}

export default EmprestimoConsignadoPublico
