import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`

  @media ${device.desktopXL} {
    min-height: 750px;
    padding-top: 96px!important;
    padding-bottom: 96px!important;
  }
`

export const CardWrapper = styled.div`

& + & {
  margin-top: 24px;

  @media ${device.tablet} {
    margin-top: 0;
  }
}

  @media ${device.tablet} {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      margin-top: 24px;
    }
  }

  @media ${device.desktopLG} {
    &:nth-child(3) {
      margin-top: 0;
    }
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid ${grayscale[200]};

  background: white;
  border-radius: 16px;
  padding: 16px 12px;
  min-height: 123px;


  @media ${device.tablet} {
    min-height: 118px;
    height: 190px;
  }

  @media ${device.desktopXL} {
    padding: 16px 14px;
  }

  @media ${device.desktopXL} {
    height: 210px;
  }
`

export const CardTitle = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 24px;
`

export const CardDescription = styled.span`
  display: block;

  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};

  @media ${device.desktopXL} {
    font-size: 18px;
    line-height: 22px;
  }
`
