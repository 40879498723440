import React from 'react'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import Summary from 'src/components/Summary'

import { Section } from './style'

const Links = [
  {
    text: 'Simulador',
    to: '#hero-section',
  },
  {
    text: 'Área do cliente',
    to: 'https://consignado-cliente.bancointer.com.br/propostas',
    redirect: true,
  },
  {
    text: 'Condições especiais',
    to: '#condicoes-especiais',
  },
  {
    text: 'Para quem?',
    to: '#contratar-emprestimo-consignado',
  },
  {
    text: 'Vantagens',
    to: '#vantagens',
  },
  {
    text: 'Portabilidade',
    to: '#portabilidade-consignado',
  },
  {
    text: 'Como contratar',
    to: '#como-contratar-emprestimo-consignado',
  },
  {
    text: 'Indique e ganhe',
    to: '#indique-e-ganhe',
  },
  {
    text: 'Abertura de conta',
    to: '#abertura-de-conta',
  },
]

const WhatAreYouLookingFor = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Summary
              icon='userdoubt'
              title='O que você procura?'
              links={Links}
              section='dobra_2'
              sendDatalayerEvent={sendDatalayerEvent}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhatAreYouLookingFor
