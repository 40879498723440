import React, { useEffect, useReducer, useState } from 'react'
import * as URLs from 'src/config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { getCookie, getParameterByName } from 'src/shared/helpers'
import payrollSimulatorReducer, { actions, initialState, PAYROLL_TYPE } from './utils'

import { initTheme, Theme } from '@interco/inter-ui'
import { Modal } from 'src/components/Modal'
import { CloseButton, Container } from 'src/components/Modal/QrCodeModal/style'
import { PayrollSimulatorProps, StateProps } from 'src/components/PayrollSimulator/types'
import FormWhatsapp, { FormDataExternalPayload } from 'src/components/UI/Forms/FormWhatsapp'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { API_WHATSAPP } from 'src/config/api/Urls'
import useDomReady from 'src/hooks/window/useDomReady'
import { ModalErrorView } from './components/ErrorView'
import { ModalSuccessView } from './components/SuccessView'
import { BoxSimulador } from './style'

function currencyToFloat (source: string, currencyNumber: string) {
  const result = currencyNumber === '' ? 0 : parseInt(currencyNumber.replace(/\D/g, '')) / 100
  return source === 'range' ? result * 100 : result
}

function floatToCurrency (floatNumber: number) {
  return floatNumber.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

const PayrollSimulatorINSS = ({ page, borderColor, sectionName, sectionNumber, whatsappForm }: PayrollSimulatorProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ state, dispatch ] = useReducer(payrollSimulatorReducer, initialState(page))
  const { simulationType, inputValue, resultValue, qtdParcel, percentage, maxValue }: StateProps = state
  const [ gclid, setGclid ] = useState<string | string[] | null | undefined>(null)
  const [ fbclid, setFbclid ] = useState<string | string[] | null | undefined>(null)
  const [ gaid, setGaid ] = useState<string | string[] | null | undefined>(null)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ utmTerm, setUtmTerm ] = useState<string | string[] | null | undefined>(null)
  const [ codigoIndiqueGanhe, setCodigoIndiqueGanhe ] = useState<string | string[] | null | undefined>(null)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const domReady = useDomReady()
  let nextStepValidation
  const inputMinValue = PAYROLL_TYPE.inss.minValue
  const inputMaxValue = PAYROLL_TYPE.inss.maxValue

  if (simulationType === 'total') {
    nextStepValidation = inputValue < inputMinValue
  } else {
    nextStepValidation = resultValue < inputMinValue
  }

  function changeInput (evt: { target: { name: string; value: string } }) {
    const inputName = evt.target.name
    let inputValue = evt.target.value

    if (simulationType === 'total') {
      if (currencyToFloat(inputName, inputValue) > inputMaxValue) {
        inputValue = `${inputMaxValue}00`
      }
    }

    const insertValue = currencyToFloat(inputName, inputValue)
    dispatch(actions.setInputValue(insertValue))
  }

  function setSimulationType (simulationType) {
    dispatch(actions.setSimulationType(simulationType))
  }

  function changeParcel (qtdParcel) {
    dispatch(actions.setQtdParcel(qtdParcel))
  }

  useEffect(() => {
    initTheme(Theme.PF)
    changeParcel(84)
    setGclid(getParameterByName('gclid', ''))
    setFbclid(getParameterByName('fbclid', ''))
    setGaid(getCookie('_ga'))
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
    setUtmTerm(getParameterByName('utm_term', ''))
    setCodigoIndiqueGanhe(getParameterByName('codigoIndiqueGanhe', ''))
  }, [])

  function handleButtonClick () {
    const parametersGclid = gclid ? `&gclid=${gclid}` : ''
    const parametersFbclid = fbclid ? `&fbclid=${fbclid}` : ''
    const parametersGaid = gaid ? `&gaid=${gaid}` : ''
    const parametersUtmSource = utmSource ? `&utmSource=${utmSource}` : ''
    const parametersUtmMedium = utmMedium ? `&utmSMedium=${utmMedium}` : ''
    const parametersUtmCampaign = utmCampaign ? `&utmSCampaign=${utmCampaign}` : ''
    const parametersUtmContent = utmContent ? `&utmContent=${utmContent}` : ''
    const parametersUtmTerm = utmTerm ? `&utmTerm=${utmTerm}` : ''
    const parametersUtmCodigo = codigoIndiqueGanhe ? `&codigoIndiqueGanhe=${codigoIndiqueGanhe}` : ''

    const urlTotal = `${URLs.API_CONSIGNADO_PRIVADO}?tipoSimulacao=1&valorTotal=${inputValue.toFixed(2)}&valorParcela=${resultValue.toFixed(2)}&qtdParcelas=${qtdParcel}&familia=publico&origem=site&landingPage=inss${parametersGaid}${parametersGclid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}${parametersUtmCodigo}`
    const urlParcel = `${URLs.API_CONSIGNADO_PRIVADO}?tipoSimulacao=2&valorTotal=${resultValue.toFixed(2)}&valorParcela=${inputValue.toFixed(2)}&qtdParcelas=${qtdParcel}&familia=publico&origem=site&landingPage=inss${parametersGaid}${parametersGclid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}${parametersUtmCodigo}`

    if (typeof window !== 'undefined') {
      if (simulationType === 'total') {
        window.location.href = urlTotal
      } else {
        window.location.href = urlParcel
      }
    }

    sendDatalayerEvent({
      section: sectionNumber,
      section_name: sectionName,
      element_action: 'click button',
      element_name: 'Simular meu empréstimo',
      redirect_url: simulationType === 'total' ? urlTotal : urlParcel,
    })
  }

  const [ isError, setIsError ] = useState(false)
  const [ isSuccess, setIsSuccess ] = useState(false)
  const isFormClean = !isSuccess && !isError

  const formData: FormDataExternalPayload = {
    templateId: 'simulacao_inss_wht',
    metadata: {
      categoria_conversa: 'GR - Geral',
      matricula: 'Nao se aplica',
      campanhaOrigem: 'wpp_lp_inss',
    },
  }

  const onFormSubmit = () => {
    sendDatalayerEvent({
      section: 'm_dobra_01',
      element_action: 'submit',
      element_name: 'Continuar pelo Whatsapp',
      section_name: 'Para continuar, precisamos de algumas informações pessoais',
    })
  }

  return (
    <BoxSimulador borderColor={borderColor} page={page} sectionNumber={sectionNumber}>
      {domReady && (
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
          <Container isModal={isModalOpen} style={{ maxWidth: '40%', minWidth: '360px' }}>
            <CloseButton onClick={() => setIsModalOpen(false)}>
              <OrangeDsIcon icon='exit' size='MD' color='#ff7a00' />
            </CloseButton>
            {isFormClean && (
              <FormWhatsapp
                onSuccessHandler={() => setIsSuccess(true)}
                onErrorHandler={() => setIsError(true)}
                formData={formData}
                apiUrl={API_WHATSAPP}
                onSubmitHandler={onFormSubmit}
                formTitle='Para continuar, precisamos de algumas informações pessoais'
              />
            )}
            {isSuccess && (
              <div className='col-12 pt-2 pt-lg-3 pt-xl-4'>
                <ModalSuccessView />
              </div>
            )}
            {isError && (
              <div className='col-12 pt-2 pt-lg-3 pt-xl-4'>
                <ModalErrorView />
              </div>
            )}
          </Container>
        </Modal>
      )}
      <div className='options mx-auto'>
        <div className='row'>
          <div className='col-12 mx-auto'>
            <ul className='select'>
              <li className={`${simulationType === 'total' ? 'active' : ''}`} onClick={() => { setSimulationType('total') }}><span>Valor Total</span></li>
              <li className={`${simulationType === 'parcel' ? 'active' : ''}`} onClick={() => { setSimulationType('parcel') }}><span>Parcela/mês</span></li>
            </ul>
          </div>
          <div className='col-12 mx-auto text-center'>
            { simulationType === 'total'
              ? <p className='fs-12 lh-17 fs-xl-14 lh-xl-16 text-grayscale--400 fw-400'>De quanto você precisa?</p>
              : <p className='fs-12 lh-17 fs-xl-14 lh-xl-16 text-grayscale--400 fw-400'>Quanto gostaria de pagar de parcela?</p>
            }
            <div className='d-flex line-bottom mb-2 justify-content-center align-items-center'>
              <span className='cifra-inss mr-2'>R$</span>
              <input
                type='text'
                name='valor'
                value={floatToCurrency(inputValue)}
                onChange={(evt: { target: { name: string; value: string } }) => changeInput(evt)}
                className='border-0 font-sora value-money'
              />
            </div>
            <span className='range-slider'>
              <input
                type='range'
                name='range'
                tabIndex={-1}
                min={1600}
                max={maxValue}
                value={inputValue}
                onChange={(evt: { target: { name: string; value: string } }) => changeInput(evt)}

              />
              <span className='slider-container'>
                <span className='bar'>
                  <span style={{ width: `${percentage}%` }} />
                </span>
                <span className='bar-btn' style={{ left: `${percentage}%` }} />
              </span>
            </span>
          </div>

          <div className='col-12 col-lg-11 mx-auto text-center justify-content-center mt-lg-1 px-0'>
            <p className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-16 text-grayscale--400 mb-2 d-block'>Em quantas parcelas?</p>
            <ul className='mt-1 mb-2 parcels mt-2 mx-auto'>
              {
                PAYROLL_TYPE[page].parcels.map((parcel: number) => (
                  <li key={parcel}>
                    <button
                      onClick={() => { changeParcel(parcel) }}
                      className={`${qtdParcel === parcel ? 'active' : ''}`}
                    >{parcel}
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className='col-12 text-center mt-lg-2'>
            { simulationType === 'total'
            ? <span className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-16 text-grayscale--400'>Valor aproximado da parcela/mês:</span>
            : <span className='fs-10 lh-12 fs-lg-12 lh-lg-14 fs-xl-14 lh-xl-16 text-grayscale--400'>Valor aproximado do empréstimo:</span>}
            <span className='d-block fs-24 lh-30 fw-400 text-orange--base mb-0 py-3 pt-md-0 pb-md-2 font-sora'>
              <span className='text-grayscale--300'>R$</span> {floatToCurrency(resultValue)}
            </span>
            <span className='rate d-block text-grayscale--400 mb-xl-2'>Os valores podem variar de acordo com o convênio</span>
          </div>
          <div className='col-12 mx-auto justify-content-center mt-3 text-center'>
            <button
              disabled={nextStepValidation}
              onClick={() => handleButtonClick()}
              className='buttonSend fw-700'
            >
              Simular meu empréstimo
            </button>
          </div>
          {/* {whatsappForm && (
            <div className='col-12 mx-auto justify-content-center mt-3 text-center'>
              <WhatsappButton
                variant='secondary'
                fullWidth
                onClick={() => {
                  setIsModalOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: ' Empréstimo para aposentados e pensionistas: simples e seguro para você!',
                    element_action: 'click button',
                    element_name: 'Simular pelo Whatsapp',
                  })
                }}
              >
                Simular pelo Whatsapp
                <Whatsapp
                  height={24}
                  width={24}
                  color={green[200]}
                  className='ml-2'
                />
              </WhatsappButton>
            </div>
          )} */}
        </div>
      </div>
    </BoxSimulador>
  )
}

export default PayrollSimulatorINSS
