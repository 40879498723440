import styled from 'styled-components'
import { breakpoints, device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'

export const Section = styled.section`
  a {
    font-size: 12px;
  }
`

export const Number = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${orange.extra};
  color: white;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
`
