/* eslint-disable no-console */
import { ReactElement } from 'react'
import { IValidateChildrenActionText, IValidateElementActionSubmit, IDataLayerLog } from './typesDataLayerEvent'

export const validateURL = (url: string): string => {
  let urlLink = ''
  if (url) {
    const pathURL = url.startsWith('/')
    if (pathURL) {
      urlLink = `https://inter.co${url}`
    } else {
      urlLink = url
    }
  } else {
    urlLink = '0'
  }

  return urlLink
}

export const validateChildrenActionText = ({ elementAction, setElementName, childrenActionText, formTitle }: IValidateChildrenActionText) => {
  if (typeof childrenActionText === 'object') {
    setElementName(childrenActionText.filter((data: string | ReactElement) => typeof data === 'string')[0])
  } else {
    if (elementAction === 'click button') {
      setElementName(childrenActionText)
    } else {
      formTitle && setElementName(formTitle)
    }
  }
}

export const validateElementActionSubmit = ({ elementAction, formTitle }: IValidateElementActionSubmit) => {
  if (elementAction === 'submit' && formTitle === undefined) {
    throw new Error('Ao utilizar o valor "submit" na propriedade elementAction, por favor atribua o título do formulário na propriedade formTitle.')
  }
}

export const dataLayerLog = ({ dataLayerObject }: IDataLayerLog) => {
  if (process.env.ENVIROMENT !== 'production') {
    console.log(dataLayerObject)
  }
}
