import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import { grayscale } from 'src/styles/colors'

export const Section = styled.section`

`

export const Card = styled.div`
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${grayscale[200]};
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media ${device.desktopLG} {
      width: 360px;
      margin: 0 auto;
    }
  }
`
