import React, { useState, useLayoutEffect } from 'react'
import Img from 'gatsby-image'
import 'react-multi-carousel/lib/styles.css'
import Carousel, { ArrowProps } from 'react-multi-carousel'

// Data
import bannersJSON from './assets/data/data.json'

// Hooks
import useWidth from 'src/hooks/window/useWidth'

// Components
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'

// PageContext
import usePageQuery from '../../pageQuery'

// Styles
import { Section, Figure, Circle } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Types
type DataProps = {
  link?: string | null;
  image: string;
  title: string;
  description: string;
}

const devices = {
  desktop: {
    breakpoint: {
      max: 4000,
      min: 1024,
    },
    items: 1,
    partialVisibilityGutter: 250,
  },
  tablet: {
    breakpoint: {
      max: 1023,
      min: 465,
    },
    items: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
}

const ArrowRight = ({ onClick }: ArrowProps) => (
  <Circle className='right' onClick={onClick} type='button'>
    <OrangeIcon color='#FF7A00' icon='chevron-right' size='MD' />
  </Circle>
)

const ArrowLeft = ({ onClick }: ArrowProps) => (
  <Circle className='left' onClick={onClick} type='button'>
    <OrangeIcon color='#FF7A00' icon='chevron-left' size='MD' />
  </Circle>
)

const WIDTH_MD = 769

const CarouselNovidades = () => {
  const imagesData = usePageQuery()
  const [ isMobile, setIsMobile ] = useState(false)
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  useLayoutEffect(() => {
    setIsMobile(windowWidth < WIDTH_MD)
  }, [ windowWidth ])

  return (
    <Section className='pb-4 pb-lg-5 bg-white' id='condicoes-especiais'>
      <div className='container mt-5'>
        <Carousel
          additionalTransfrom={0}
          arrows
          centerMode={false}
          partialVisible={!isMobile}
          draggable
          focusOnSelect={false}
          keyBoardControl
          minimumTouchDrag={80}
          responsive={devices}
          customRightArrow={<ArrowRight />}
          customLeftArrow={<ArrowLeft />}
          showDots
          dotListClass='dots'
          renderDotsOutside
          infinite
          swipeable
          itemClass='item pl-2 pr-2'
        >
          { bannersJSON.map((item: DataProps) => (
            <Figure key={item.title}>
              {item.link ? (
                <a
                  href={item.link}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: item.title,
                      element_action: 'click button',
                      element_name: 'null',
                      redirect_url: item.link,
                    })
                  }}
                >
                  <Img fluid={imagesData[item.image].fluid} alt='Ilustração Super App Inter' />
                  <figcaption>
                    <div className='text'>
                      <h3
                        className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-400 text-white mb-2'
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 fw-700 text-white' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  </figcaption>
                </a>
              ) : (
                <>
                  <Img fluid={imagesData[item.image].fluid} alt='Ilustração Super App Inter' />
                  <figcaption>
                    <div className='text'>
                      <h3
                        className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-400 text-white mb-2'
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 fw-700 text-white' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  </figcaption>
                </>
                )
              }
            </Figure>
            ),
          )}
        </Carousel>
      </div>
    </Section>
  )
}

export default CarouselNovidades
