import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
// Styles

import * as S from './style'

const PortabilidadeDeEmprestimoConsignado = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section id='portabilidade-consignado'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-lg-6'>
            <h3 className='fs-24 lh-30 fs-md-32 lh-md-40 text-white'>Portabilidade de Empréstimo Consignado</h3>
            <p className='fs-16 lh-20 text-white'>
              Sabia que é possível economizar no seu empréstimo para aposentados e pensionistas do INSS ou pegar mais dinheiro rápido e sem burocracias? Aqui no Inter, é você que escolhe!
            </p>
            <p className='fs-16 lh-20 text-white'>
              Faça a Portabilidade do seu Consignado INSS de outras instituições financeiras para o Inter e reduza agora mesmo o valor das suas parcelas ou ganhe um troco.
            </p>
          </div>
          <div className='col-12 col-lg-5 offset-lg-1'>
            <div>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://portabilidade-consignado.bancointer.com.br/onboarding'
                className='btn btn-white btn--lg fs-14 fw-600 rounded-2 mx-auto text-orange--extra text-none mw-100'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    section_name: 'Portabilidade de Empréstimo Consignado',
                    element_action: 'click button',
                    element_name: 'Quero fazer a Portabilidade',
                    redirect_url: 'https://portabilidade-consignado.bancointer.com.br/onboarding',
                  })
                }}
              >
                Quero fazer a Portabilidade
              </a>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default PortabilidadeDeEmprestimoConsignado
