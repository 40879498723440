import { Button } from '@interco/inter-ui/components/Button'
import { breakpoints } from 'src/styles/breakpoints'
import { gray, grayscale, green, orange, white } from 'src/styles/colors'
import styled from 'styled-components'
import { PayrollSimulatorProps } from '../types'

type PropsStyles = {
  borderColor?: string;
  page?: string;
}

export const BoxSimulador = styled.div<PayrollSimulatorProps>`
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  border: 1px solid ${({ borderColor }: PropsStyles) => borderColor ? borderColor : orange.extra};
  box-shadow: 0 0 40px rgba(60, 112, 190, 0.16);
  border-radius: 24px;
  overflow: hidden;

  @media (min-width: ${breakpoints.md}) {
    max-width: 259px;
    border-radius: 27px;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 285px;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 350px;
  }

  .options {
    background: ${white};
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 10px 24px 25px 24px;

    @media (min-width: ${breakpoints.md}) {
      max-width: 472px;
    }
    @media (min-width: ${breakpoints.lg}) {
      padding: 24px 36px;
    }
    @media (min-width: ${breakpoints.xl}) {
      padding-bottom: 32px;
    }

    button.bt-green-desk {
      height: 40px;
      @media (min-width: ${breakpoints.lg}) {
        max-width: 300px;
      }
    }

    .text--error {
      color: #C5232A !important;
    }

    ul {
      gap: 5px !important;
      -webkit-box-pack: center !important;
      justify-content: center !important;
    }

    ul.select {
      float: left;
      list-style: none;
      padding: 0;
      width: 100%;
      li {
        position: relative;
        color: ${gray[300]};
        cursor: pointer;
        float: left;
        font-weight: 600;
        font-size: 12px;
        height: 36px;
        line-height: 35px;
        text-align: center;
        width: 50%;

        @media ${breakpoints.lg} {
        font-size: 14px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          border-radius: 1px 1px 0 0;
          background: #DEDFE4;
        }

        span {
          color: ${gray[500]};
          &.taxa {
            color: ${gray[600]};
          }
        }

        &.active {
          &::after {
            height: 4px;
            border-radius: 4px 4px 0 0;
            background: ${orange.extra};
          }

          span {
            color: ${orange.extra};
          }
        }

        &:last-child {
          border-radius: 0 20px 20px 0;
        }
      }
    }

    ul.parcels {
      display: flex;
      gap: 10px;
      list-style: none;
      padding: 0;
      align-items: center;
      flex-wrap: wrap;
      width: 170px;
      justify-content: space-between;

      li {
        margin-top: 8px;

        button {
          border-radius: 8px;
          background: ${white};
          color: #FF9D42;
          border: 1px solid #FF9D42;
          font-weight: 500;
          width: 42px;
          height: 30px;
          padding: 0;
          outline: none;
          font-size: 12px;

          &.active {
            background: ${orange.base};
            color: ${white};
          }
        }
      }
    }

    span {
      &.rate {
        font-size: 10px;
        line-height: 12px;

        @media (min-width: ${breakpoints.md}) {
          font-size: 7px;
          line-height: 8px;
        }
      }
    }

    strong {
      color: ${gray[950]};

      span {
        color: ${gray[700]};
        font-weight: normal;
        opacity: 0.7;
      }
    }

    .cifra {
      color: ${gray[300]};
      display: block;
      font-size: 28px;
      font-weight: normal;
      font-family: 'Sora';

      @media (min-width: ${breakpoints.md}) {
        font-size: 22px;
      }

      @media (min-width: ${breakpoints.xl}) {
        font-size: 28px;
      }
    }

    .line-bottom {
      border-bottom: 1px solid ${grayscale[200]};

      input {
        outline: none;
        overflow: hidden;
        background-color: ${white};
        color: ${gray[950]};
        font-family: 'Sora';
      }
      ~ p {
        color: ${gray[950]};
      }
    }

    // Inicio Slider
    .range-slider {
      box-sizing: border-box;
      display: inline-block;
      padding-bottom: 10px;
      position: relative;
      width: 100%;
      z-index: 0;

      & > input {
        cursor: grab;
        opacity: 0;
        position: relative;
        width: 100%;
        z-index: 5;

        &::-webkit-slider-thumb {
          appearance: none;
          border-radius: 10px;
          height: 30px;
          width: 50px;
          position: relative;
          z-index: 100;
        }
      }

      & > span.slider-container {
        box-sizing: border-box;
        display: inline-block;
        left: -8px;
        right: 46px;
        position: absolute;
        z-index: 3;

        & > span.bar {
              background-color: ${gray[500]};
              border-radius: 10px;
              display: inline-block;
              left: 10px;
              height: 8px;
              overflow: hidden;
              position: absolute;
              right: -32px;
              top: 10px;
              z-index: 1;
          & > span {
            background: linear-gradient(2.37deg, ${orange.dark} 0%, ${orange.base} 100%);
            display: block;
            float: left;
            height: 11px;
          }
        }
        & > span.bar-btn {
          display: inline-block;
          left: 0;
          position: absolute;
          top: -62px;

          &:after {
            content: "";
            background-color: ${white};
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.05);
            border-radius: 20px;
            height: 22px;
            left: 10px;
            position: absolute;
            top: 65px;
            width: 22px;
            z-index: 3;
          }
        }
      }
    }
  }

  .buttonSend {
    width: 100%;
    height: 45px;
    color: ${white};
    background: ${orange.extra};
    border-radius: 8px;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 17px;

    &:focus {
      outline: none;
    }

    &[disabled] {
      cursor: default;
      background: #ccc;
    }
  }
`

export const WhatsappButton = styled(Button)`
  border: 1px solid ${green[200]};
  color: ${grayscale[500]};
  background: #fff;
  &:hover:enabled {
    background-color: #fff !important;
    border: 1px solid ${green[200]} !important;
    color: ${grayscale[500]} !important;
  }
`
