import React from 'react'

// Components
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Style
import { Alert } from './style'

const MensageAlerta = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='bg-white pt-4'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-xl-10 offset-xl-1 mb-5'>
            <Alert>
              <div className='mr-2'>
                <OrangeIcon color='#161616' icon='attention' size='SM' />
              </div>
              <p className='fs-12 lh-14 fw-400 text-grascale--500 m-0'>
                Prezamos pelo seu bem-estar. Se julgar que o contato do Banco Inter para promoções e serviços por telefone está invasivo,
                acesse
                <a
                  href='https://www.naomeperturbe.com.br/'
                  className='fw-700'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_10',
                      section_name: 'Prezamos pelo seu bem-estar. Se julgar que o contato do Banco Inter para promoções e serviços por telefone está invasivo, acesse',
                      element_action: 'click button',
                      element_name: 'www.naomeperturbe.com.br',
                      redirect_url: 'https://www.naomeperturbe.com.br/',
                    })
                  }}
                >
                  www.naomeperturbe.com.br
                </a> para bloquear as ligações.
              </p>
            </Alert>
          </div>
          <div className='col-12 col-xl-10 offset-xl-1 mb-4'>
            <p className='fs-14 lh-17 lh-md-14 lh-lg-16 text-grayscale-400'>
              “O Empréstimo Consignado INSS tem prazo mínimo 24 meses e máximo 84 meses. Um exemplo desse empréstimo é o seguinte: se você pegar um empréstimo de R$25.000,00 em 84 parcelas e com parcelas de R$608,81, o valor à pagar seria de R$51.140,04 com taxa de juros de 1,85% ao mês. Nessa operação, o Custo Efetivo Total (CET) é de 1,91% ao mês e 25,46% ao ano.”
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MensageAlerta
