import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: #F5F6FA;
  padding-top: 33px;
  padding-bottom: 33px;

  @media ${device.tablet} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`
