import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

import BackgroundLG from '../../assets/images/background-hero-consorcio-inss-lg.png'
import BackgroundMD from '../../assets/images/background-hero-consorcio-inss-md.png'
import BackgroundXL from '../../assets/images/background-hero-consorcio-inss-xl.png'

type SectionProps = {
  cHeight: number;
}

export const Section = styled.section<SectionProps>`
  background-color: ${white} !important;

  .bread-crumb a {
    color: ${grayscale[500]} !important;
  }

  @media ${device.tablet} {
    min-height: 515px;
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 270px);
  }
  @media ${device.desktopXL} {
    min-height: 625px;
  }

  .container {
    background-repeat: no-repeat;
    background-size: auto ${({ cHeight }: SectionProps) => cHeight < 433 ? `calc(${cHeight}px - 12px)` : '421px'};
    background-position: bottom 80px center;

    @media ${device.tablet} {
      background-image: none;
    }

    @media ${device.desktopLG} {
      padding-bottom: 0;
    }

    .row {
      .grid-right {

        @media ${device.desktopLG} {
          padding-right: 0;
        }

        @media ${device.desktopXL} {
          padding-right: 12px;
        }
      }
    }
  }

  .bread-crumb {
    font-family: 'Sora';

    span {
      padding-left: 1px;
      font-weight: bold !important;
      color: ${orange.extra} !important;
      opacity: 1;
    }
    a {
      font-weight: 400 !important;
      color:${grayscale[400]};
      opacity: 0.7;
    }
  }


  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
  }

  .cifra-inss {
    font-size: 32px;

    @media ${device.tablet} {
      font-size: 22px;
    }
    @media ${device.desktopXL} {
      font-size: 32px;
    }
  }

  .value-money {
    width: 100%;
    font-size: 32px;

    @media ${device.tablet} {
      font-size: 22px;
    }
    @media ${device.desktopXL} {
      font-size: 32px;
    }
  }
`

export const Image = styled.div`
   padding-top: 20px;
`

export const Alert = styled.div`
  background: #FFF7D9;
  padding-top: 12px;
  padding-bottom: 12px;

  div, button {
    svg {
      display: block;
    }
  }

  button {
    width: auto;
    background: none;
    border: none;
    padding: 0;
    height: auto;

    &:focus {
      outline: none;
    }
  }
`

export const CTAs = styled.div`
  a {
    width: 100%;
    max-width: 456px !important;
  }
`

export const Simulator = styled.div`
  position: relative;

  @media ${device.tablet} {
    background-size: 340px;
  }

  @media ${device.desktopLG} {
    background-size: 376px;
  }

  @media ${device.desktopXL} {
    background-size: 456px;
  }

  .result-simulator {
    font-size: 28px;

    @media ${device.tablet} {
      font-size: 22px;
    }
    @media ${device.desktopXL} {
      font-size: 28px;
    }
  }

  img {
    display: none;

    @media ${device.tablet} {
      position: absolute;
      display: initial;
      width: 500px;
    }

    @media ${device.desktopLG} {
      width: 580px;
    }

    @media ${device.desktopXL} {
      margin-left: 60px;
      padding-right: 46px;
    }

    @media ${device.desktopXXL} {
      margin-left: 60px;
    }

    @media ${device.desktopXXXL} {
      margin-left: 190px;
    }
  }
`
