import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Alert = styled.div`
  display: flex;
  align-items: baseline;
  padding: 16px 8px;
  background: #F5F6FA;
  border-radius: 8px;

  @media (min-width: ${breakpoints.md}) {
    padding: 16px 12px;
  }
`
