import React from 'react'

import Img from 'gatsby-image'

// PageContext
import usePageQuery from '../../pageQuery'

// Styles
import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const IndiqueEGanhe = () => {
  const imagesData = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section className='py-5 bg-white' id='indique-e-ganhe'>
      <div className='container py-md-5'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <Img fluid={imagesData.bannerIndiqueEGanhe.fluid} alt='Pessoas felizes com smartphone na mão sentadas no sofá' />
          </div>
          <div className='col-12 col-md-6 offset-lg-1'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-4'>
              Indique e Ganhe Consignado INSS
            </h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Você e seus amigos podem fazer uma renda extra sem precisar sair de casa!
            </p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Indique quantos amigos aposentados ou pensionistas do INSS quiser para o Consignado Inter, para <span className='fw-700'>cada um de vocês ganharem R$ 150.</span>
            </p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Garanta suas compras do mês, faça uma reserva de dinheiro, aproveite bons momentos com a família.
            </p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Clique abaixo e confira o passo a passo de como indicar!
            </p>
            <a
              href='https://blog.inter.co/indique-e-ganhe-consignado'
              target='_blank'
              rel='noreferrer'
              className='fs-14 fw-600 btn btn--lg btn-orange--extra text-none rounded-3 mt-3 mb-md-0 mw-100'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  section_name: 'Indique e Ganhe Consignado INSS',
                  element_action: 'click button',
                  element_name: 'Veja como indicar!',
                  redirect_url: 'https://blog.inter.co/indique-e-ganhe-consignado',
                })
              }}
            >
              Veja como indicar!
            </a>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default IndiqueEGanhe
