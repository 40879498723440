import React, { useRef } from 'react'

import BreadCrumb from 'src/components/Breadcrumb'
import PayrollSimulator from 'src/components/PayrollSimulator/PayrollSimulatorINSS/_index'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { CTAs, Section, Simulator } from './style'

const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const spacingHeight = useRef<HTMLDivElement>(null)
  const customHeight = spacingHeight?.current?.clientWidth || 340

  return (
    <Section
      cHeight={customHeight}
      ref={spacingHeight} id='hero-section'
      className='d-md-flex position-relative align-items-md-center justify-content-md-center py-5 py-md-0'
    >
      <div className='container'>
        <div className='row align-items-center align-items-md-center justify-content-between'>
          <div className='col-12 col-md-6 pr-xl-5'>
            <div className='mb-3'>
              <BreadCrumb
                sectionName='Consignado INSS: simples e seguro para você!'
                type='Pra você'
                text='Consignado INSS'
                link='/pra-voce/emprestimos/emprestimo-consignado-inss/'
              />
            </div>
            <h1 className='fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500'>
              Empréstimo para aposentados e pensionistas: simples e seguro para você!
            </h1>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 mb-4 mb-md-5 text-grayscale--400'>
              Dinheiro rápido e descomplicado para aposentados e pensionistas do INSS usarem como quiserem,
              tendo a garantia de segurança Inter.
            </p>
            <CTAs className='d-none d-md-block'>
              <a
                href=' https://emprestimo.bancointer.com.br/'
                target='_blank'
                rel='noreferrer'
                className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 fw-700 text-none mb-5 mb-md-0'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Empréstimo para aposentados e pensionistas: simples e seguro para você!',
                    element_action: 'click button',
                    element_name: 'Já tem uma proposta? Acompanhe',
                    redirect_url: 'https://emprestimo.bancointer.com.br/',
                  })
                }}
              >
                Já tem uma proposta? Acompanhe
              </a>
            </CTAs>
          </div>
          <Simulator className='col-12 col-md-6 col-lg-5 d-flex justify-content-center justify-content-lg-right grid-right px-0'>
            <PayrollSimulator
              page='inss'
              borderColor='#DEDFE4'
              sectionName='Empréstimo para aposentados e pensionistas: simples e seguro para você!'
              sectionNumber='dobra_1'
              whatsappForm
            />
          </Simulator>
          <div className='col-12 pt-4 d-block d-md-none'>
            <CTAs>
              <a
                href='https://emprestimo.bancointer.com.br/'
                target='_blank'
                rel='noreferrer'
                className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 fw-700 text-none'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Empréstimo para aposentados e pensionistas: simples e seguro para você!',
                    element_action: 'click button',
                    element_name: 'Já tem uma proposta? Acompanhe',
                    redirect_url: 'https://emprestimo.bancointer.com.br/',
                  })
                }}
              >
                Já tem uma proposta? Acompanhe
              </a>
            </CTAs>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
