import React, { useEffect, useState } from 'react'
import { sendDataWithoutMainObject } from 'src/shared/helpers'
import { validateURL, validateChildrenActionText, validateElementActionSubmit, dataLayerLog } from './utilsDataLayerEvent'
import { IDataLayerEventProps } from './typesDataLayerEvent'

const DataLayerEvent = ({
  children,
  sectionOrder,
  elementAction,
  formTitle,
  submitStep,
  buttonText,
  }: IDataLayerEventProps) => {
    const [ elementName, setElementName ] = useState('')

    const childrenActionText = children.props.children
    const childrenURL = children.props.to || children.props.href

    useEffect(() => {
      validateChildrenActionText({ elementAction, setElementName, childrenActionText, formTitle })
      validateElementActionSubmit({ elementAction, formTitle })
    }, [])

  const handleClick = () => {
    const dataLayerObject = {
      event: 'ga_event_body',
      params: {
        section: `dobra_${sectionOrder}`,
        element_action: elementAction,
        element_name: buttonText || elementName,
        step: submitStep ? submitStep : '0',
        redirect_url: validateURL(childrenURL),
      },
    }

    dataLayerLog({ dataLayerObject })
    sendDataWithoutMainObject(dataLayerObject)
  }

  return (
    <div onClick={handleClick}>
      {children}
    </div>
  )
}

export default DataLayerEvent
