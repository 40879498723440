import React from 'react'
import UserAccount from 'inter-frontend-svgs/lib/orangeds/LG/user-account'

// Styles
import * as S from './style'

const QuemPodeContratarOEmprestimo = () => {
  return (
    <S.Section className='py-5 bg-white' id='contratar-emprestimo-consignado'>
      <div className='container'>
        <div className='row align-items-xl-center'>
          <div className='col-12 col-lg-6 col-xl-5 pr-0'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 mb-4 mb-md-5 text-md-center text-lg-left mb-xl-0'>Quem pode contratar o Empréstimo Consignado INSS?</h2>
          </div>
          <div className='col-12 col-lg-5 col-xl-6 offset-lg-1'>
            <S.Card className='d-flex'>
              <div className='mr-2'>
                <UserAccount color='#ff7a00' />
              </div>
              <div>
                <p className='fs-20 lh-25 fs-md-24 lh-md-30 fw-600 text-grayscale--500 mb-2 font-sora'>Aposentados e pensionistas INSS</p>
                <p className='fs-16 lh-20 fs-md-18 lh-md-22 fw-400 text-grayscale--500'>O Crédito Consignado INSS é uma modalidade de empréstimo mais barata e simples.</p>
              </div>
            </S.Card>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default QuemPodeContratarOEmprestimo
