import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import Background from '../../assets/images/background-tenha-ainda-mais-beneficios.png'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
background-color: ${grayscale[100]} !important;
  @media ${device.desktopLG} {
    background: url(${Background}) no-repeat;
    background-position: 732px center;
  }

  @media ${device.desktopXL} {
    background-position: right center;
  }

  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      width: 456px;
    }
  }
`
